// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--3f958";
export var heroImage = "index-module--heroImage--2600f";
export var showHero = "index-module--showHero--02790";
export var arrow = "index-module--arrow--80e61";
export var heroLogo = "index-module--heroLogo--d9d2b";
export var contactFlex = "index-module--contactFlex--d4bbc";
export var contactIcon = "index-module--contactIcon--612f7";
export var highlightedText = "index-module--highlightedText--cd71b";
export var homepageHeader = "index-module--homepageHeader--82395";
export var detailCards = "index-module--detailCards--49de9";
export var cardWrapper = "index-module--cardWrapper--3fe1f";
export var cardDetails = "index-module--cardDetails--84110";
export var detailLink = "index-module--detailLink--1eba0";
export var sectionCard = "index-module--sectionCard--1a4b9";