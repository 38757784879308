import * as styles from './ServiceCard.module.css';
import { Link } from 'gatsby';
import kitchenImage from '../../../images/Work Examples/kitchen.jpg'
import bathroomImage from '../../../images/Work Examples/homepage_bathroom.jpg';
import basementImage from '../../../images/Work Examples/basement.jpg';
import carpentryImage from '../../../images/Work Examples/homepage_carpentry.jpg';

import React from 'react';



const Service = (props) => {
 
    const services = {
        kitchen: {
            label: 'Kitchens',
            name: 'kitchen',
            alt: 'kitchens',
            link: '/services/kitchen',
            linkText: 'Check out what I can do for your kitchen',
            copy: 'A well-designed kitchen is intuitive to navigate, but mastering the countless details that create such a space is challenging. How will the lighting interplay with the cabinetry? Does the drain need to be lowered to accommodate a deeper sink? Can this wall be removed? A good kitchen remodeler needs to thrive on these sorts of questions. I combine my knowledge with the expertise of local kitchen designers to create spaces that are as functional as they are beautiful. Remaking a kitchen is still one of the toughest projects you can undertake, but with the right team you are guaranteed to build a space you will love for decades.',
            imageSource: kitchenImage
        },
        bathroom: {
            label: 'Bathrooms',
            alt: 'bathrooms',
            name: 'bathroom',
            link: '/services/bathroom',
            linkText: 'Check out what I can do for your bathroom',
            copy: 'Bathroom renovations often seem to be delayed longer than is advisable. The process is daunting, but postponing the project can be more painful than completing it. Bathrooms require as much attention to detail in their foundations as they do in their finishing touches – beautiful ceramic tile installed over insufficient waterproofing will not stand the test of time. I pride myself in the details, and I am constantly evolving as new technologies become available to add value to this important space.',
            imageSource: bathroomImage
        },
        basement: {
            label: 'Basements',
            alt: 'basements',
            name: 'basement',
            link: '/services/basement',
            linkText: 'Check out how I can transform your basement',
            copy: 'A basement renovation can add as much square footage as a small addition at a fraction of the cost. Today’s basements often include kitchens and bathrooms, so experience in those arenas is essential. That said, underground spaces carry their own unique challenges: uneven floors, thoughtlessly placed mechanicals and special insulation requirements. In the end, whether you simply want a sanctuary to watch the game or you dream of an entire house worth of amenities I can help you achieve your goals.',
            imageSource: basementImage
        },
        carpentry: {
            label: 'Carpentry',
            alt: 'carpentry',
            name: 'carpentry',
            link: '/services/carpentry',
            linkText: 'Check out my carpentry skills',
            copy: 'Carpentry is probably my favorite trade. I love working with wood. I like crisp miter joints and doors that close with satisfying ease. Whether you simply require new trim as part of a flooring install or you yearn for an elaborate balustrade, I would be thrilled to help you.',
            imageSource: carpentryImage
        }
        // flooring: {
        //     label: 'Flooring',
        //     alt: 'flooring',
        //     name: 'flooring',
        //     link: '/services/flooring',
        //     copy: 'This is some copy about what mike does for floors. This is some copy about what mike does for floors.This is some copy about what mike does for floors.',
        //     imageSource: flooringImage
        // },
    }

    const service = services[props.service];
    
    let serviceElement = <div className={styles.service}>
        <img className={styles.desktopImage} src={service.imageSource} alt={service.alt} />
        <div className={styles.serviceDetails}>
            <h3>{service.label}</h3>
            <img className={styles.mobileImage} src={service.imageSource} alt={service.alt} />
            <p className={styles.copy}>
                {service.copy}
            </p>
            <Link to={service.link} className={styles.detailLink}>{service.linkText} &#8212;&#8250;</Link>

        </div>
    </div>

    return serviceElement;
}
    export default Service;

