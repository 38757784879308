import * as styles from '../page-styles/index.module.css';
import logo from '../images/mkr-official-logo-stylized2.png';
import phoneIcon from '../images/phone-icon.png';
import mailIcon from '../images/mail-icon.png';
import ServiceCard from '../components/home/ServiceCard/ServiceCard';
import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/seo';

import heroImage from '../images/Work Examples/FB_IMG_1513476535860.jpg';

import { useState, useEffect } from 'react';

const Home = () => {
    const serviceCategories = [
        'bathroom',
        'kitchen',
        'basement',
        'carpentry'
    ]
    const [heroClasses, setHeroClasses] = useState([styles.hero]);

    const scrollToContent = ()=>{
        const welcomeCard = document.getElementById('welcome-card');
        const cumulativeOffset = (element)=> {
            let top = 0;
            let left = 0;
            do {
                top += element.offsetTop  || 0;
                left += element.offsetLeft || 0;
                element = element.offsetParent;
                console.log(element);
            } while(element);
        
            return {
                top: top,
                left: left
            };
        };
        window.scrollTo({
            top: cumulativeOffset(welcomeCard).top - 30,
            left: 0,
            behavior: 'smooth'
        })
    }

   useEffect(()=>{
       if(typeof window !== 'undefined'){
    window.scrollTo(0, 0);
       }
    setHeroClasses((prevClasses)=>{
        return [...prevClasses, styles.showHero];
    })
   }, [])
   
    return (

        <Layout location='home'>
            <Seo title='Michael Kolonay Renovations' />

            <main >
                <section  className={heroClasses.join(' ')}>
                    <img className={styles.heroImage} src={heroImage} alt="hero"/>
                    <img className={styles.heroLogo} src={logo} alt="logo" />
                    <h1>Exceptional Results Require Clear Communication</h1>
                    <Link to='/work-request'>Request a project from Mike</Link>
                    <div className={styles.arrow} onClick={scrollToContent}>
                        <div></div>
                        <div></div>
                    </div>
                </section> 

                <section id="welcome-card" className={styles.detailCards}>

                    <article className={styles.cardWrapper}>
                        <div className={styles.cardDetails}>
                            <h2 className={styles.homepageHeader}>Welcome to Michael Kolonay Renovations</h2>

                            <p><span className={styles.highlightedText}><b><i>Exceptional Results Require Clear Communication.</i></b></span> More than a motto: it’s a promise. Poor communication is a plague on the construction industry
                                – the root cause of nearly every negative contractor experience. I strive to be different.
                                From initial contact to final invoice, I do my best to provide open, honest dialogue.
                                I welcome the opportunity to exceed your expectations.</p>
                            <Link to='/about' className={styles.detailLink}>Learn More about my company and philosophy &#8212;&#8250;</Link>
                        </div>
                    </article>

                    <article className={[styles.cardWrapper, styles.sectionCard].join(" ")}>

                        <h2 className={styles.homepageHeader}>What I can help with</h2>


                        {serviceCategories.map((category) => {
                            return (
                                <div className={styles.cardWrapper} key={`${category} section`}>
                                    <ServiceCard service={category} />
                                </div>
                            )
                        })}
                    </article>
                    <article className={styles.cardWrapper}>
                        <h2 className={styles.homepageHeader}>Contact</h2>
                        <div className={styles.contactFlex}>
                            <div className={styles.contactIcon}>
                                <img src={phoneIcon} alt="phone" />
                            </div>
                            <a href="tel:7242610032">724-261-0032</a>
                        </div>
                        <div className={styles.contactFlex}>
                            <div className={styles.contactIcon}>
                                <img src={mailIcon} alt="email" />
                            </div>
                            <p>michaelkolonay@kolonayrenovations.com</p>
                        </div>
                    </article>
                </section>

            </main>
        </Layout>
    )
}

export default Home;